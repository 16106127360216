import $ from "jquery";

//part of library

function initDropdownMenu($ddm) {
    $ddm.on("mouseenter touchstart", "li.level1.hassubmenu", function(ev) {
        const $clickedElement = $(ev.target);
        if($clickedElement.closest(".level2").length > 0) {
            //Event is bubbled from am level2 Link => do nothing
            return;
        }

        const $item = $(this);
        const isDropdownOpen = $item.hasClass("dropdownmenuopen");

        ev.preventDefault();

        hideDropdownMenus();

        $item.addClass("dropdownmenuopen");

        const $submenu = $item.find("ul").first();
        $submenu.addClass("visible");
        //fixPosition($submenu);

        $(document).trigger("dropdownmenu:show", {
            "$submenu": $submenu
        });
    });
}

function fixPosition($ddmenu) {
    const docw = $(document).width();
    const menuRightEdge = $ddmenu.offset().left+$ddmenu.outerWidth();
    const posFromRight = docw-menuRightEdge;
    if(posFromRight < 16) {
        $ddmenu.css("right", "16px");
    }
}

function getDropdownMenu() {
    return $("ul.dropdownmenu");
}

function getAllDropdownMenus() {
    return $("ul.dropdownmenu .level1 > ul");
}

function hideDropdownMenus() {
    const $ddmenus = getAllDropdownMenus();
    $ddmenus.removeClass("visible");
    $ddmenus.css("right", "");

    $(document).trigger("dropdownmenu:hideall");

    const $lis = getDropdownMenu().find("li.level1");
    $lis.removeClass("dropdownmenuopen");
}

$(function() {
    $("ul.dropdownmenu").each(function() {
        const $ddm = $(this);
        initDropdownMenu($ddm);
    });

    $("main").on("mouseenter click", function() {
        hideDropdownMenus();
    });
});
import $ from "jquery";
import { clamp } from "./utils.js"; 
import { Gradient } from "whatamesh";

$(function () {
    $(".bl-hero").each(function() {
        const $bl = $(this);
        initHero($bl);
        initMeshBackground();
    });
});

function initHero($bl) {
    const $ctrswitch = $bl.find(".ctrswitch");
    const $bgcolorchange = $bl.find(".bgcolorchange");
    const $sliderbtn = $bl.find(".sliderbtn");
    const $txtswitch = $bl.find(".txtswitch");
    const minX = -2;
    let lastXPct;
    let maxX;
    $ctrswitch.on("mousedown touchstart mousemove touchmove", function(ev) {
        ev.preventDefault();
        
        const origEv = ev.originalEvent;
        const pageX = (origEv.touches ? origEv.touches[0].pageX : ev.pageX);
        const buttonPressed = origEv.touches || origEv.buttons > 0;
        if(!buttonPressed) {
            return;
        }
        const localX = pageX - $ctrswitch.offset().left;
        const sliderButtonWidth = $sliderbtn.outerWidth();
        maxX = $ctrswitch.outerWidth()-sliderButtonWidth;
        const xPos = clamp(localX-sliderButtonWidth/2, minX, maxX);
        const xPct = (xPos-minX) / (maxX-minX);
        $sliderbtn.css("left", xPos+"px");
        $bgcolorchange.css("opacity", xPct);
        if(xPct > 0.5) {
            $txtswitch.addClass("gt50");
            $txtswitch.text("Let's go!");
        } else {
            $txtswitch.removeClass("gt50");
            $txtswitch.text("Switch");
        }
        lastXPct = xPct;
    });
    $(document).on("mouseup touchend", function(ev) {
        if(lastXPct > 0) { //> 0 => always, > 0.5 => only when slider halfway dragged
            //animate to end
            $sliderbtn.animate({
                "left": maxX+"px"
            }, 200);
            $bgcolorchange.css("opacity", 1);
            $txtswitch.addClass("gt50");
            $txtswitch.text("Let's go!");

            //go to url
            const url = $ctrswitch.closest(".ctrimpacttoollink").data("url");
            if(url) {
                window.location.href = url;
            }
        } else {
            $sliderbtn.animate({
                "left": minX+"px"
            }, 200);
            $bgcolorchange.css("opacity", 0);
        }
    });
}

function initMeshBackground() {
    const selector = "#hero-gradient-canvas";
    if($(selector).length == 0) {
        return;
    }
    const gradient = new Gradient();
    gradient.initGradient(selector);
}
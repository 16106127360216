import $ from "jquery";

$(function () {
    $(".bl-renovationstrategy").each(function() {
        const $bl = $(this);
        initRenovationStrategy($bl);
    });
});

function initRenovationStrategy($bl) {
    const $steps = $bl.find(".step");
    $bl.on("touchstart", ".step", function() {
        const $step = $(this);
        $steps.removeClass("active");
        $step.addClass("active");
    });
}
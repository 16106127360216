import $ from "jquery";

/*
function isElementInViewportPartial(el) {
	var rect = el.getBoundingClientRect();
	var innerh = window.innerHeight || document.documentElement.clientHeight;
    const enteredViewport = rect.top <= innerh*0.4; //Higher number: Show earlier
    const leftViewport = rect.bottom < innerh*0.4; //Lower number: Show longer
	return enteredViewport && !leftViewport;
}
*/

$(function() {
    const $html = $("html");
    const $body = $("body");

    function getSectionAt(xpos, ypos) {
        const elements = document.elementsFromPoint(xpos, ypos);
        for(const element of elements) {
            if(element.tagName === "SECTION") {
                return element;
            }
        }
        return null;
    }

    let currentBgColor = "";
    function checkUpdateBackgroundColor() {
        const currentSection = getSectionAt(window.innerWidth*0.5, window.innerHeight*0.5);
        const newBgColor = (currentSection ? $(currentSection).data("bgcolor") : "default");

        if(newBgColor != currentBgColor) {
            updateBackgroundColor(currentBgColor, newBgColor);
            /*
            document.body.dispatchEvent(new CustomEvent("custom:updateBackgroundColor", {
                detail: {
                    oldColor: currentBgColor,
                    newColor: newBgColor
                }
            }));
            */
            currentBgColor = newBgColor;
        }
    }

    function updateBackgroundColor(currentBgColor, newBgColor) {
        if(newBgColor == "black") {
            $body.addClass("bg-black");
        } else {
            $body.removeClass("bg-black");
        }
    }
    
    window.setInterval(checkUpdateBackgroundColor, 150);
});


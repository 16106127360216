//npm install swiper
import Swiper from 'swiper/bundle';
import { ready } from "./utils.js";

import 'swiper/css/bundle';

function initSwiper() {
    const swiper = new Swiper('.defaultswiper', {
      //loop: true,
      autoHeight: true,
      
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
    
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      /*
      speed: 1500,
      autoplay: {
        delay: 4000
      }
      */

    });
}

ready(initSwiper);
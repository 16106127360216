import $ from "jquery";
//import "what-input";

window.$ = $;
window.jQuery = $;


//ViewTimeline Polyfill: https://github.com/flackr/scroll-timeline
//modified version without CSS (details see file scroll-timeline.js)
import "./libraries/polyfills/scroll-timeline.js";


//Pixmill Scripts
import { ready } from "./px/utils.js";

//import "./px/hamburger.js";
import "./px/header.js";
//import "./px/form.js";
import "./px/reveal_animationapi.js";
//import "./px/customreveal.js";
import "./px/swiper.js";

import "./px/dropdownmenu.js"; //part of library
//import "./px/pagedmenu.js"; //part of library
import "./px/accordionmenu.js"; //part of library
import "./px/desktopmenu.js";
import "./px/mobilemenu.js";

import "./px/photoswipe.js";
import "./px/scrolltohash.js";

import "./px/scrollcolor.js";

//import "./px/vorgehen.js";
import "./px/accordion.js";
import "./px/stepstoresult.js";
import "./px/whyrenovate.js";
import "./px/trustedby.js";
import "./px/partnerlist.js";
import "./px/teamlist.js";
import "./px/hero.js";
import "./px/renovationstrategy.js";
import "./px/materiallist.js";



/*
import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});
*/


import $ from "jquery";
import Swiper from 'swiper/bundle';

$(function () {
    $(".bl-materiallist").each(function() {
        const $bl = $(this);
        initSwiper($bl);
    });
});

function initSwiper($bl) {
    const swiper = new Swiper($bl.find(".swiper").get(0), {
        loop: true,
        centeredSlides: true,
        slideToClickedSlide: true,
        //Darstellungsfehler, wenn zu wenige slides: siehe https://github.com/nolimits4web/swiper/issues/7148
        //allowTouchMove:false,
        //autoHeight: true,
        
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        on: {        
            init: updateDetails,
            slideChange: updateDetails
        },

        /*
        speed: 1000,
        autoplay: {
          delay: 3000
        },
        */

        slidesPerView: 3,
        breakpoints: {
            //when window width is >= 740px
            740: {
                slidesPerView: 5,
            },
            //when window width is >= 1200px
            1200: {
                slidesPerView: 7,
            }
        }
  
    });

    function updateDetails(swiper) {
        const $ctrinfo = $bl.find(".ctrinfo");
        const $ctrdetailimg = $bl.find(".ctrdetailimg");

        const $activeSlide = $(swiper.slides[swiper.activeIndex]);
        const $tplInfo = $activeSlide.find(".tplinfo");
        const $tplDetailImg = $activeSlide.find(".tpldetailimg");
        copyContent($tplDetailImg, $ctrdetailimg);
        copyContent($tplInfo, $ctrinfo);
    }

    $bl.find(".btnprev").on("click", function() {
        swiper.slidePrev();
    });
    $bl.find(".btnnext").on("click", function() {
        swiper.slideNext();
    });
}

function copyContent($src, $dst) {
    $dst.html($src.html());        
}

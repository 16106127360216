import $ from "jquery";

$(function () {
    $(".bl-teamlist").each(function() {
        const $bl = $(this);
        initTeamList($bl);
    });
});

function initTeamList($bl) {
    const $bgmarker = $bl.find(".bgmarker");
    const $items = $bl.find(".items");

    function moveMarkerToItem($item) {
        const itemTop = $item.offset().top;
        const itemHeight = $item.outerHeight();
        $bgmarker.css({
            "top": itemTop+"px",
        });
        requestAnimationFrame(function() {
            //workaround to disable marker animation for first hover effect
            enableMarkerAnimation();
            $bgmarker.css({
                "height": itemHeight+"px"
            });
        });
    }

    function removeMarker() {
        $bgmarker.css({
            "height": 0
        });
    }

    function enableMarkerAnimation() {
        $bgmarker.addClass("animated");
    }

    const hasHover = window.matchMedia("(hover:hover)").matches;
    if(hasHover) {
        //hover over item => move marker to item
        $bl.on("mouseenter", ".item", function() {
            const $item = $(this);
            moveMarkerToItem($item);
        });
        //completely leave block => remove marker
        $bl.on("mouseleave", ".items", function() {
            removeMarker();
        });

        $bl.on("mousemove", ".item", function(ev) {
            const $item = $(this);
            const localX = ev.pageX-$item.offset().left;
            const localY = ev.pageY-$item.offset().top;
            const itemWidth = $item.outerWidth();
            const itemHeight = $item.outerHeight();
            const percentX = localX/itemWidth;
            const percentY = localY/itemHeight;

            //console.log(percentX+" / "+percentY);

            const shiftX = (percentX-0.5)*100;
            const shiftY = (percentY-0.5)*20;
            $items.css({
                "--photoxtranslate": -50+shiftX+"%",
                "--photoytranslate": -50+shiftY+"%"
            });
        });
    }
}


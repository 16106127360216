import $ from "jquery";
import { Gradient } from "whatamesh";

$(function () {
    $(".bl-whyrenovate").each(function() {
        const bl = this;
        defineAnimation(bl);
        initMeshBackground();
    });
});

function defineAnimation(bl) {
    const scrollytellingCtr = bl.querySelector(".ctrscrollytelling");

    const timeline = new ViewTimeline({
        subject: scrollytellingCtr,
        axis: "block",
        inset: [CSS.percent(0), CSS.percent(0)],
    });

    function addAnimation(el, animation, offsetStart, offsetEnd, options)
    {
        options.timeline = timeline;
        if(!options.fill) {
            options.fill = "both";
        }
        if(!options.easing) {
            options.easing = "ease";
        }
        options.rangeStart = { rangeName: 'cover', offset: CSS.percent(offsetStart*100) };
        options.rangeEnd = { rangeName: 'cover', offset: CSS.percent(offsetEnd*100) };
        el.animate(animation, options);
    }

    const cards = bl.querySelectorAll(".card");
    const numCards = cards.length;
    let cardIdx = 1;
    const animStartOffset = 0.1;
    const animEndOffset = 0.1;
    const oneCardAnimDuration = (1-animStartOffset-animEndOffset)/(numCards);
    cards.forEach(card => {
        const animationEnd = animStartOffset + cardIdx*oneCardAnimDuration;
        const disappearAnimStart = animationEnd-oneCardAnimDuration*0.9;
        const disappearAnimEnd = animationEnd;
        const rotateStyle = "rotate("+card.dataset.rotation+"deg)";
        addAnimation(card, {
            //opacity: [1, 0],
            transform: [rotateStyle, rotateStyle+" translateY(-100vh)"]
        }, disappearAnimStart, disappearAnimEnd, {});
        cardIdx++;
    });
}

function initMeshBackground() {
    const selector = "#whyrenovate-gradient-canvas";
    if($(selector).length == 0) {
        return;
    }
    const gradient = new Gradient();
    gradient.initGradient(selector);
}
import $ from "jquery";
import Swiper from 'swiper/bundle';

$(function () {
    $(".bl-trustedby").each(function() {
        const $bl = $(this);
        initSwiper($bl);
    });
});

function initSwiper($bl) {
    const swiper = new Swiper($bl.find(".swiper").get(0), {
        //loop: true,
        autoHeight: true,
        rewind: true,
        
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
  
        /*
        speed: 1500,
        autoplay: {
          delay: 4000
        }
        */
  
      });
}


import $ from "jquery";

$(function () {
    $(".bl-stepstoresult").each(function() {
        const bl = this;
        defineAnimation(bl);
    });
});

function defineAnimation(bl) {
    const scrollytellingCtr = bl.querySelector(".ctrscrollytelling");

    const timeline = new ViewTimeline({
        subject: scrollytellingCtr,
        axis: "block",
        inset: [CSS.percent(50), CSS.percent(50)], //Start der Animation, wenn scrollytellingCtr zu 50% sichtbar. Ende der Animation, wenn scrollytellingCtr noch zu 50% sichtbar. 
    });

    function addAnimation(step, el, animation, offsetStart, offsetEnd, options)
    {
        const shiftMultiplicator = 0.333;
        const shiftAdd = (step-1)*0.333;
        const shiftedOffsetStart = offsetStart*shiftMultiplicator+shiftAdd;
        const shiftedOffsetEnd = offsetEnd*shiftMultiplicator+shiftAdd;
        
        options.timeline = timeline;
        if(!options.fill) {
            options.fill = "both";
        }
        if(!options.easing) {
            options.easing = "ease";
        }
        options.rangeStart = { rangeName: 'cover', offset: CSS.percent(shiftedOffsetStart*100) };
        options.rangeEnd = { rangeName: 'cover', offset: CSS.percent(shiftedOffsetEnd*100) };
        el.animate(animation, options);
    }

    //Step 1
    const s01phone = bl.querySelector(".s01phone");
    const s01phoneimg = bl.querySelector(".s01phone img");
    const s01text = bl.querySelector(".s01text");

    addAnimation(1, s01phone, {
        transform: ["translateX(50%)", "translateX(-50%)", "translateX(-50%)", "translateX(-150%)"],
        offset: [0, 0.1, 0.9, 1], //custom timing offset
    }, 0, 1, {});
    addAnimation(1, s01phoneimg, {
        transform: ["scaleX(0.8)", "scaleX(1)", "scaleX(0.8)"]
    }, 0, 1, {});
    addAnimation(1, s01phone, {
        opacity: [0, 1, 1, 0],
        offset: [0, 0.1, 0.95, 1], //custom timing offset
    }, 0, 1, {});

    addAnimation(1, s01text, {
        transform: ["translateX(100%)", "translateX(0%)", "translateX(-0%)", "translateX(-100%)"],
        offset: [0, 0.25, 0.85, 1],
    }, 0, 1, {});
    addAnimation(1, s01text, {
        opacity: [0, 1, 1, 0],
        offset: [0, 0.1, 0.95, 1],
    }, 0, 1, {});

    //Step 2
    const s02apppanel1 = bl.querySelector(".s02apppanel1");
    const s02apppanel2 = bl.querySelector(".s02apppanel2");
    const s02apppanel3 = bl.querySelector(".s02apppanel3");
    const s02text = bl.querySelector(".s02text");

    function addAppPanelAnim(el, offsetTime) {
        addAnimation(2, el, {
            transform: ["translateX(50%)", "translateX(50%)", "translateX(0%)", "translateX(0%)", "translateX(-50%)", "translateX(-50%)"],
            offset: [0, 0.00+offsetTime, 0.20+offsetTime, 0.70+offsetTime, 0.90+offsetTime, 1], //custom timing offset
        }, 0, 1, {});
        addAnimation(2, el, {
            opacity: [0, 0, 1, 1, 0, 0],
            offset: [0, 0.00+offsetTime, 0.20+offsetTime, 0.70+offsetTime, 0.90+offsetTime, 1], //custom timing offset
        }, 0, 1, {});
    }
    addAppPanelAnim(s02apppanel1, 0.00);
    addAppPanelAnim(s02apppanel2, 0.05);
    addAppPanelAnim(s02apppanel3, 0.10);

    addAnimation(2, s02text, {
        transform: ["translateX(100%)", "translateX(0%)", "translateX(-0%)", "translateX(-100%)"],
        offset: [0, 0.25, 0.85, 1],
    }, 0, 1, {});
    addAnimation(2, s02text, {
        opacity: [0, 1, 1, 0],
        offset: [0, 0.1, 0.95, 1],
    }, 0, 1, {});

    //Step 3
    const s03text = bl.querySelector(".s03text");
    const s03house = bl.querySelector(".s03house");
    const s03icon1 = bl.querySelector(".s03icon1");
    const s03icon2 = bl.querySelector(".s03icon2");
    const s03icon3 = bl.querySelector(".s03icon3");

    function addIconAnim(el, offsetTime) {
        addAnimation(3, el, {
            transform: ["scale(0%)", "scale(0%)", "scale(100%)", "scale(100%)", "scale(0%)", "scale(0%)"],
            offset: [0, 0.00+offsetTime, 0.20+offsetTime, 0.70+offsetTime, 0.90+offsetTime, 1], //custom timing offset
        }, 0, 1, {});
        addAnimation(3, el, {
            opacity: [0, 0, 1, 1, 0, 0],
            offset: [0, 0.00+offsetTime, 0.20+offsetTime, 0.70+offsetTime, 0.90+offsetTime, 1], //custom timing offset
        }, 0, 1, {});
    }
    addIconAnim(s03icon1, 0.00);
    addIconAnim(s03icon2, 0.05);
    addIconAnim(s03icon3, 0.10);

    addAnimation(3, s03house, {
        transform: ["translateX(100%)", "translateX(0%)", "translateX(-0%)", "translateX(-100%)"],
        offset: [0, 0.1, 0.9, 1],
    }, 0, 1, {});
    addAnimation(3, s03house, {
        opacity: [0, 1, 1, 0],
        offset: [0, 0.1, 0.9, 1],
    }, 0, 1, {});

    addAnimation(3, s03text, {
        transform: ["translateX(100%)", "translateX(0%)", "translateX(-0%)", "translateX(-100%)"],
        offset: [0, 0.25, 0.95, 1],
    }, 0, 1, {});
    addAnimation(3, s03text, {
        opacity: [0, 1, 1, 0],
        offset: [0, 0.1, 0.95, 1],
    }, 0, 1, {});
}


import $ from "jquery";
import Swiper from 'swiper/bundle';

$(function () {
    $(".bl-partnerlist").each(function() {
        const $bl = $(this);
        initSwiper($bl);
    });
});

function initSwiper($bl) {
    const swiper = new Swiper($bl.find(".swiper").get(0), {
        //loop: true,
        //autoHeight: true,
        
        /*
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        */

        speed: 1000,
        autoplay: {
          delay: 3000
        },

        slidesPerView: 1,
        breakpoints: {
            //when window width is >= 740px
            740: {
                slidesPerView: 3,
            },
            //when window width is >= 1400px
            1400: {
                slidesPerView: 5,
            }
        }
  
      });
}

